import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

const defaultStyle = Object.freeze({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  '@media (max-width: 800px)' : {
    width: '95%'
  },
  maxHeight: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  overflow: 'scroll',
});

const Popup = ({ open, onClose, title, children, styles }) => {
  const finalStyle = { ...defaultStyle, ...styles };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={finalStyle}>
        <IconButton onClick={onClose} sx={{float: 'right', mt: -2, mr: -2, p: '4px'}}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" component="h3" sx={{m: 0, pb: 1, borderBottom: "2px solid #eee"}}>
          {title}
        </Typography>
        {children}
      </Box>
    </Modal>
  );
};

Popup.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  styles: PropTypes.object,
};

Popup.defaultProps = {
  styles: {},
};

export default Popup;
