import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const FormLabel = styled(Typography)(({ theme }) => ({
  textAlign: "right",
  padding: theme.spacing(1),
  verticalAlign: "center",
}));

export default FormLabel;
