/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import {
  DashboardOutlined,
  PreviewOutlined,
  Waves,
} from '@mui/icons-material';

const cfg = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: DashboardOutlined,
  },
  {
    title: 'Report',
    href: '/report',
    icon: PreviewOutlined,
  },
  {
    title: 'Job List',
    href: '/jobs',
    icon: Waves,
  }
];

export default cfg;
