import React from 'react';
import PropTypes from 'prop-types';
import { Typography, colors } from '@mui/material';

const defaultStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 0,
  flexShrink: 0,
  borderRadius: 'shape.borderRadius',
  lineHeight: '10px',
  fontSize: '10px',
  height: 20,
  minWidth: 20,
  whiteSpace: 'nowrap',
  paddingX: 1,
  paddingY: 0.5,
};

const Label = props => {
  const { variant, color, shape, children, styles, ...rest } = props;

  const roundStyle = shape === 'rounded' ? { borderRadius: 10, padding: 0.5 } : {};
  const finalStyle = { ...defaultStyle, ...roundStyle, ...styles };

  if (variant === 'contained') {
    finalStyle.backgroundColor = color;
    finalStyle.color = '#FFF';
  } else {
    finalStyle.border = `1px solid ${color}`;
    finalStyle.color = color;
  }

  return (
    <Typography
      {...rest}
      sx={finalStyle}
      variant="overline"
    >
      {children}
    </Typography>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  shape: PropTypes.oneOf(['square', 'rounded']),
  styles: PropTypes.object,
  variant: PropTypes.oneOf(['contained', 'outlined'])
};

Label.defaultProps = {
  styles: {},
  color: colors.grey[600],
  variant: 'contained',
  shape: 'square'
};

export default Label;
