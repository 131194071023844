import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

const Error = props => {
  return (
    <main style={{height: '100%'}}>
      <Suspense fallback={<LinearProgress />}>
        <Outlet />
      </Suspense>
    </main>
  );
};

export default Error;
