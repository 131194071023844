// just a simple emulation using MUI for:
// https://github.com/fkhadra/react-toastify
import React, { forwardRef, useState, useEffect } from 'react';
import {
  Alert,
  Snackbar,
} from '@mui/material';

// the ToastContainer component is expected to be using only once in the whole react tree, but if
// it is used multiple times by accident, it should also work well.
let containers = [];

let queue = [];

// level: error, warning, info, success
export function toast(content, level='success') {
  const msg = {content, level};
  if (containers.length === 0) {
    queue.push(msg);
  } else {
    containers[0](msg);
  }
}

function addContainer(cnr) {
  if (!containers.includes(cnr))
    containers.unshift(cnr);

  if (queue.length > 0) {
    // only show the last one for now
    cnr(queue[queue.length-1]);
    queue = [];
  }
}

function removeContainer(cnr) {
  const i = containers.indexOf(cnr);
  if (i >= 0)
    containers = containers.splice(i, 1);
}


const MyAlert = forwardRef((props, ref) =>
  <Alert elevation={6} ref={ref} variant="filled" {...props} />
);

export const ToastContainer = () => {
  const [msg, setMsg] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cnr = (msg) => {
      setMsg(msg);
      setOpen(true);
    };
    addContainer(cnr);
    return () => removeContainer(cnr);
  }, []);

  const handleClose = (_ev, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  if (!msg)
    return null;

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
    >
      <MyAlert
        onClose={handleClose}
        severity={msg.level}
        sx={{
          width: '100%',
          fontWeight: 500,
          fontSize: '0.875rem',
          letterSpacing: '0.01em',
        }}
      >
        {msg.content}
      </MyAlert>
    </Snackbar>
  );
};
