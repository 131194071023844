import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';

import useSession from 'store/session';

// Example of user roles: ['guest', 'user', 'admin'];

const AuthGuard = ({roles, children}) => {
  const loggedIn = useSession((state) => state.loggedIn);
  const session = useSession((state) => state.session);
  const loc = useLocation();

  if (loggedIn && session) {
    //console.log(roles, session);
    if (roles.includes(session.role)) {
      return children;
    } else {
      return <Navigate to="/errors/401" />;
    }
  }
  return <Navigate to="/auth/login" state={{ from: loc }} replace />;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired
};

AuthGuard.defaultProps = {
  roles: []
};

export default AuthGuard;
