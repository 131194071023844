import { create } from "zustand";

import { callApi } from 'utils';
import { hookLoggedIn } from './session';

const defaultMisc = {
  org: {},
  traffic_curve: {},
  device_filter: {},
  ant_cfg: {},
};

const useMisc = create((set, _get) => ({
  data: defaultMisc,
  updateData: () => callApi('/misc')
    .then(resp => set({ data: {...resp} }))
    .catch(() => set({ data: defaultMisc })),
}));

// load data if loagged in
hookLoggedIn(() => useMisc.getState().updateData());

export default useMisc;
