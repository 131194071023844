import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from "@uidotdev/usehooks";
import {
  Button,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Search as SearchIcon,
} from '@mui/icons-material';

import { useSession } from 'store';
import { Page } from 'components';
import { AggReport } from './components';
import { callApi, formatFieldName } from 'utils';

function reload(sp, q, [page, limit], cb) {
  sp.set('q', q);
  callApi(`/reports?${sp.toString()}&page=${page}&limit=${limit}`)
    .then(cb).catch(console.error);
}

const Report = () => {
  const [search, setSearch] = useState('');
  const [searchParams] = useSearchParams();
  const q = useDebounce(search, 1500);

  searchParams.has("dim") || searchParams.set('dim', 'jobdaily');

  const session = useSession((state) => state.session);
  const isAdmin = session.role === 'admin';

  const [pagination, setPagination] = useState([0, 50]);
  const [data, setData] = useState(null);
  const [agg, setAgg] = useState('');

  useEffect(() => reload(searchParams, q, pagination, setData), [searchParams, q, pagination]);

  const title = data?.title || 'Report';

  const handlePageChange = useCallback((_, page) => setPagination(prev => [page, prev[1]]), []);
  const handleRowsPerPageChange = useCallback((ev) => setPagination(prev => [prev[0], ev.target.value]), []);
  const reloadReport = useCallback(() => reload(searchParams, q, pagination, setData), [searchParams, q, pagination]);
  const closeModal = useCallback(() => setAgg(''), []);

  const handleFilter = useCallback((ev) => {
    const t = ev.target;
    if (t.name === 'search') {
      setSearch(t.value);
    } else {
      //setFilter(prev => ({...prev, [t.name]: t.value}));
    }
    setPagination(prev => [0, prev[1]]);
  }, []);

  return (
    <Page title={title}>
      <Toolbar sx={{mt: -1}}>
        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>{title}</Typography>
        {searchParams.get('dim') === 'jobdaily' && (
        <Input
          sx={{p: '2px', mr: 1, width: 160}}
          name="search"
          value={search}
          size="small"
          onChange={handleFilter}
          inputProps={{ style: {marginLeft: '-5px'} }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        )}
        {isAdmin && ([
        <Button color="inherit" key="d" onClick={() => setAgg('daily')}>Daily</Button>,
        <Button color="inherit" key="m" onClick={() => setAgg('monthly')}>Monthly</Button>,
        <Button color="inherit" key="p" onClick={() => setAgg('plan')}>Plan</Button>,
        <Button color="inherit" key="r" onClick={reloadReport}>Reload</Button>,
        ])}
      </Toolbar>
      <Paper sx={{p: 1, pb: 0, m: 2, mt: -1}}>
        <TableContainer sx={{overflowX: "initial"}}>
          <Table stickyHeader sx={{
            minWidth: 650,
            '& th,td': {
              padding: 1,
              textAlign: 'center',
              whiteSpace: 'pre-line',
            }
          }}>
            <TableHead>
              <TableRow>
                {data?.columns.map(col => (<TableCell key={col}>{formatFieldName(col)}</TableCell>))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!data?.rows.length && (<TableRow><TableCell colSpan={data?.columns.length || 1}>No data</TableCell></TableRow>)}
              {data?.rows.map(row => (
                <TableRow hover key={row.key}>
                  {data?.columns.map(col => (<TableCell key={col}>{row[col]}</TableCell>))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {data?.total > 0 && (
        <TablePagination
          component="div"
          count={data?.total || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={pagination[0]}
          rowsPerPage={pagination[1]}
          rowsPerPageOptions={[30, 50, 100]}
        />
        )}
      </Paper>
      <AggReport agg={agg} onClose={closeModal} />
    </Page>
  );
};

export default Report;
