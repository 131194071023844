import React, { useState, useEffect, useCallback } from 'react';
import joi from 'joi';
import dayjs from 'dayjs';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  CalendarViewMonthOutlined as CalendarIcon,
  Clear as ClearIcon,
  Tune as TuneIcon,
} from '@mui/icons-material';
import { DatePicker, TimeField } from '@mui/x-date-pickers';
import { enableMapSet } from 'immer';
import { useImmer } from "use-immer";

import { useMisc } from 'store';
import { Page, FormLabel } from 'components';
import { callApi, validate } from 'utils';
import { ScheduleEditor } from './components';
import { postbackTypes } from 'consts';

enableMapSet();

const schema = joi.object({
  org: joi.number().label('Organization').required(),
  name: joi.string().label('Name').required(),
  start_date: joi.object().label('StartDate').invalid(null).required(),
  end_date: joi.object().label('EndDate').invalid(null).required(),
  ctr: joi.number().label('CTR').min(0).max(100).required(),
  daily_imps: joi.object({
    default: joi.number().label('Default Traffic').integer().min(0).required(),
  }),
  dayparts: joi.object({
    default: joi.array().label('Default Dayparts').items(joi.object()).optional(),
  }),
  overrun: joi.number().label('Overrun').integer().min(0).required(),
  is_adx: joi.bool().label('From Adx'),
  adx_tagid: joi.string().label('Adx TagID').allow(''),
  //  .when('is_adx', { is: true, then: joi.pattern(new RegExp('^[0-9a-f]{16}$')), otherwise: joi.allow('') }),
  dest_url: joi.string().label('Landing Url').allow(''),
  trackers: joi.object(),
  traffic_curve: joi.string(),
  postback: joi.object(),
  fc_id: joi.number().integer(),
  fc_max: joi.number().integer().required(),
  landing: joi.number().integer().required(),
  device_filter: joi.string().allow(''),
  ant_cfg: joi.string().allow(''),
  ant_cfg_cust: joi.string().allow(''),
  region_names: joi.string().allow(''),
  parent: joi.number().integer(),
  extra: joi.string().allow(''),
  customize: joi.string().allow(''),
});

const JobForm = () => {
  const { jobId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const misc = useMisc((state) => state.data);
  const copyJob = searchParams.get('copy');

  const [formState, setFormState] = useImmer({
    values: {
      org: 1,
      name: '',
      start_date: null,
      end_date: null,
      ctr: 0,
      daily_imps: {default: 0},
      dayparts: {default: []},
      overrun: 20,
      is_adx: false,
      adx_tagid: '',
      dest_url: '',
      trackers: {imp: [''], clk: ['']},
      traffic_curve: 'default',
      postback: new Set(),
      fc_id: 0,
      fc_max: 1,
      landing: 100,
      device_filter: '',
      ant_cfg: 'click-randpos.yml',
      ant_cfg_cust: '',
      region_names: '',
      regions: null,
      parent: 0,
      extra: '',
      customize: '',
    },
    // warren wants it always be shown
    customizeSchedule: true,
    touched: {},
    errors: null,
  });
  const [saving, setSaving] = useState(false);

  // for edit or copy job
  useEffect(() => {
    const getId = jobId || copyJob;
    if (!getId)
      return;
    callApi(`/jobs/${getId}`).then(job => {
      let daily_imps;
      if (copyJob) {
        job.fc_id = job.fc_id || copyJob;
        job.parent = job.parent || copyJob;
        // do not copy these fields
        daily_imps = {default: 0};
      } else {
        daily_imps = job.daily_imps;
      }

      const dayparts = {};
      Object.entries(job.dayparts || {}).forEach(([day, dps]) =>
        dayparts[day] = dps.map(t => dayjs('2022-02-22 '+t))
      );
      if (dayparts.default === undefined) {
        // set default if has other custom dayparts
        dayparts.default = Object.keys(dayparts).length ? [dayjs('2022-02-22 00:00'), dayjs('2022-02-22 23:59')] : [];
      }

      const is_adx = job.dest_url.startsWith('adx:');

      let postback;
      if (job.postback)
        postback = new Set(job.postback === 'all' ? Object.keys(postbackTypes) : job.postback.split(','));
      else
        postback = new Set();

      delete job.suspend;
      if (!jobId) {
        delete job.id;
      }
      if (misc['ant_cfg'][job.ant_cfg] === undefined) {
        job.ant_cfg_cust = job.ant_cfg;
        job.ant_cfg = '_cust_';
      }
      setFormState({
        values: {
          ...job,
          start_date: dayjs(job.start_date),
          end_date: dayjs(job.end_date),
          daily_imps,
          dayparts,
          is_adx,
          adx_tagid: is_adx ? job.dest_url.substring(4) : '',
          dest_url: is_adx ? '' : job.dest_url,
          trackers: {
            imp: job.trackers?.imp || [''],
            clk: job.trackers?.clk || [''],
          },
          postback,
          extra: (job.extra || '').replaceAll('@@', '\n'),
        },
        // warren wants it always be shown
        //customizeSchedule: dayparts.default.length > 1 || Object.keys(daily_imps).length > 1,
        customizeSchedule: true,
        touched: {},
        errors: null,
      });
    }).catch(console.error);
  }, [jobId, copyJob, misc, setFormState]);

  const { values: vals, errors: errs, touched } = formState;

  const handleChange = useCallback((ev) => {
    const t = ev.target;
    setFormState(draft => {
      let touchField = t.name;
      if (t.name === 'postback') {
        draft.values.postback = new Set(t.checked ? Object.keys(postbackTypes) : []);
      } else if (t.name.startsWith('pb_type_')) {
        const type = t.name.substring(8);
        const set = draft.values.postback;
        if (t.checked)
          set.add(type);
        else
          set.delete(type);
        touchField = 'postback';
      } else if (t.name === 'region_names') {
        draft.values[t.name] = t.value;
        draft.values['regions'] = null;
      } else {
        draft.values[t.name] = t.type === 'checkbox' ? t.checked : t.value;
      }
      draft.touched[touchField] = true;
    });
  }, [setFormState]);
  const changeDate = (name, val) => setFormState(draft => {
    draft.values[name] = val;
    draft.errors = validate(draft.values, schema);
  });
  const changeDefaultDayparts = (i, val) => setFormState(draft => {
    draft.values.dayparts.default[i] = val;
  });
  const changeSchedule = useCallback((name, key, value) => {
    //console.log('schedule:', name, key, value);
    if (name === 'daily_imps') value |= 0;
    setFormState(draft => { draft.values[name][key] = value });
  }, [setFormState]);

  const handleBlur = useCallback(() => {
    //console.log(vals, errors);
    setFormState(draft => { draft.errors = validate(draft.values, schema) });
  }, [setFormState]);

  const handleValidateRegions = useCallback((ev) => {
    let val = ev.target.value;
    if (val === '') return;
    callApi(`/misc/regions/${encodeURIComponent(val)}`).then(res => {
      if (res.error) {
        setFormState(draft => { draft.errors = Object.assign(draft.errors||{}, {'regions': res.error}) });
      } else {
        setFormState(draft => {
          draft.values['regions'] = res.codes;
          delete draft.errors?.regions;
          if (draft.errors && Object.keys(draft.errors).length === 0)
            draft.errors = null;
        });
      }
    });
  }, [setFormState]);

  const disableSubmit = Boolean(errs) || saving;
  Boolean(errs) && console.log(errs);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (disableSubmit) {
      return false;
    }

    const errors = validate(vals, schema);
    //console.log('onsubmit:', errors);
    if (errors) {
      setFormState(draft => {
        draft.errors = errors;
        Object.keys(errors).forEach(field => draft.touched[field] = true);
      });
      return false;
    }
    setSaving(true);

    // normalize daily_imps
    const daily_imps = {};
    const defaultImps = Number(vals.daily_imps.default);
    Object.entries(vals.daily_imps)
      .filter(([_, v]) => Number(v) !== defaultImps)
      .forEach(([k, v]) => daily_imps[k] = Number(v));
    daily_imps.default = defaultImps;

    // normalize dayparts
    const dayparts = {};
    if (vals.dayparts.default.length > 0) {
      const wholeDay = '00:00-23:59';
      const dflt = vals.dayparts.default.map(d => d.format('HH:mm')).join('-');
      const excl = [dflt, wholeDay];
      Object.entries(vals.dayparts)
        .map(([k, v]) => [k, v.map(t => t.format('HH:mm'))])
        .filter(([_, v]) => !excl.includes(v.join('-')))
        .forEach(([k, v]) => dayparts[k] = v);
      if (dflt !== wholeDay)
        dayparts.default = dflt.split('-');
    }

    let dest_url, trackers;
    if (vals.is_adx) {
      dest_url = `adx:${vals.adx_tagid.trim()}`;
      trackers = {};
    } else {
      dest_url = vals.dest_url.trim();
      // normalize trackers
      trackers = {
        imp: vals.trackers.imp.map(s => s.trim()).filter(s => s.startsWith('http')),
        clk: vals.trackers.clk.map(s => s.trim()).filter(s => s.startsWith('http')),
      };
      trackers.imp.length > 0 || (delete trackers.imp);
      trackers.clk.length > 0 || (delete trackers.clk);
    }

    let regions = '';
    if (vals.regions !== null) {
      regions = vals.regions;
    } else if (vals.region_names !== '') {
      regions = 'names:' + vals.region_names;
    }

    const data = {
      ...vals,
      start_date: vals.start_date.format('YYYY-MM-DD'),
      end_date: vals.end_date.format('YYYY-MM-DD'),
      daily_imps,
      dayparts,
      dest_url,
      trackers,
      regions,
      postback: vals.postback.size === Object.keys(postbackTypes).length ? 'all' : Array.from(vals.postback).join(','),
      ant_cfg: vals.ant_cfg === '_cust_' ? vals.ant_cfg_cust : vals.ant_cfg,
      // not saving \n for psql display and easy backup of job table
      extra: vals.extra.replaceAll('\n', '@@'),
    };
    delete data.is_adx;
    delete data.adx_tagid;
    delete data.ant_cfg_cust;

    let url, method;
    if (jobId) {
      url = `/jobs/${jobId}`;
      method = 'PUT';
    } else {
      url = '/jobs';
      method = 'POST';
    }
    callApi(url, method, data)
      //.then(console.log)
      .then((res) => {
        if (res.errors) {
          setFormState(draft => {
            draft.errors = res.errors;
            Object.keys(res.errors).forEach(field => draft.touched[field] = true);
          });
          setSaving(false);
        } else {
          navigate('/jobs');
        }
      }).catch(() => setSaving(false));
  };

  const hasError = (field) => Boolean(touched[field] && errs?.[field]);

  const setCustomizeSchedule = () => setFormState(draft => { draft.customizeSchedule = true });
  const showEditDayparts = () => setFormState(draft => {
    draft.values.dayparts.default = [dayjs('2022-02-22 00:00'), dayjs('2022-02-22 23:59')];
    //draft.customizeSchedule = true;
  });
  const clearDayparts = () => setFormState(draft => {
    draft.values.dayparts = {default: []};
    //draft.customizeSchedule = Object.keys(draft.values.daily_imps).length > 1;
  });

  const changeTrackers = (type, i, value) => {
    setFormState(draft => { draft.values.trackers[type][i] = value })
  };
  const addTracker = (type) => {
    setFormState(draft => { draft.values.trackers[type].push('') })
  };

  return (
    <Page styles={{padding: 3}} title={jobId ? 'Edit Job' : 'Add Job'}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Card>
            <form>
              <CardHeader title={jobId ? `Edit Job ${jobId}: ${vals.name}` : "Create Job"} />
              <Divider />
              <CardContent>
                <Grid container spacing={1} wrap="wrap" sx={{ '& .MuiTypography-subtitle1': {mb: 0} }}>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Organization:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <Select
                        name="org"
                        value={vals.org}
                        size="small"
                        onChange={handleChange}
                      >
                      {Object.entries(misc['org']).map(([val, display]) => (
                        <MenuItem value={val|0} key={val}>{display}</MenuItem>
                      ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Name:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <OutlinedInput
                        sx={{padding: 0, width: 400}}
                        name="name"
                        value={vals.name}
                        error={hasError("name")}
                        size="small"
                        inputProps={{ style: {textAlign: 'left'} }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Start / End:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <DatePicker
                        sx={{ width: 150, '& .MuiInputBase-input': {py: '8.5px', pl: '12px'} }}
                        value={vals.start_date}
                        views={['year', 'month', 'day']}
                        slotProps={{
                          textField: { error: hasError("start_date") }
                        }}
                        onChange={val => changeDate('start_date', val)}
                        dayOfWeekFormatter={(_, date) => dayjs(date).day() || 7}
                      />
                      <span style={{margin: '0 6px', height: '100%', display: 'inline-flex', alignItems: 'center' }}>~</span>
                      <DatePicker
                        sx={{ width: 150, '& .MuiInputBase-input': {py: '8.5px'} }}
                        value={vals.end_date}
                        views={['year', 'month', 'day']}
                        minDate={vals.start_date}
                        slotProps={{
                          textField: { error: hasError("end_date") }
                        }}
                        onChange={val => changeDate('end_date', val)}
                        dayOfWeekFormatter={(_, date) => dayjs(date).day() || 7}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Dayparts:</FormLabel>
                    </Grid>
                    <Grid item xs sx={{display: "flex", alignItems: "center"}}>
                      { vals.dayparts.default.length === 0 ? (<>
                        <Typography>Whole day</Typography>
                        <IconButton onClick={showEditDayparts} size="small">
                          <TuneIcon />
                        </IconButton>
                      </>) : (<>
                        <TimeField
                          sx={{
                            '& .MuiInputBase-root': {width: '55px'},
                            '& .MuiInputBase-input': {p: '5px'},
                          }}
                          format="HH:mm"
                          value={vals.dayparts.default[0]}
                          onChange={val => changeDefaultDayparts(0, val)}
                        />
                        <span style={{margin: "0 6px"}}>~</span>
                        <TimeField
                          sx={{
                            '& .MuiInputBase-root': {width: '55px'},
                            '& .MuiInputBase-input': {p: '5px'},
                          }}
                          format="HH:mm"
                          value={vals.dayparts.default[1]}
                          onChange={val => changeDefaultDayparts(1, val)}
                        />
                        <IconButton onClick={clearDayparts} size="small">
                          <ClearIcon />
                        </IconButton>
                      </>)}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">CTR(%):</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <FormControl>
                        <OutlinedInput
                          sx={{padding: 0, width: 140}}
                          name="ctr"
                          value={vals.ctr}
                          error={hasError("ctr")}
                          size="small"
                          inputProps={{ style: {textAlign: 'right'} }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                        <FormHelperText sx={{marginLeft: 0}}>
                          {errs?.ctr ? (
                            <span style={{color: "red"}}>{errs.ctr}.</span>
                          ) : (
                            <span><em>100%</em> means all are clicks.</span>
                          )}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Daily Traffic:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <FormControl>
                        <OutlinedInput
                          sx={{padding: 0, width: 140}}
                          value={vals.daily_imps.default}
                          error={hasError("daily_imps.default")}
                          size="small"
                          inputProps={{ style: {textAlign: 'right'} }}
                          onChange={ev => changeSchedule('daily_imps', 'default', ev.target.value)}
                          onBlur={handleBlur}
                          required
                          endAdornment={
                            formState.customizeSchedule ? null : (
                              <InputAdornment position="end">
                                <IconButton disableRipple onClick={setCustomizeSchedule}>
                                  <CalendarIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                        />
                        <FormHelperText sx={{marginLeft: 0}}>
                          {errs?.['daily_imps.default'] ? (
                            <span style={{color: "red"}}>{errs['daily_imps.default']}.</span>
                          ) : (
                            <span><em>Caution</em> review all after changing default.</span>
                          )}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Overrun(%):</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <FormControl>
                        <OutlinedInput
                          sx={{padding: 0, width: 140}}
                          name="overrun"
                          value={vals.overrun}
                          error={hasError("overrun")}
                          size="small"
                          inputProps={{ style: {textAlign: 'right'} }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                        <FormHelperText sx={{marginLeft: 0}}>
                          {errs?.overrun ? (
                            <span style={{color: "red"}}>{errs.overrun}.</span>
                          ) : (
                            <span>Overrun percentage based on daily number.</span>
                          )}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Landing Rate(%):</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <FormControl>
                        <OutlinedInput
                          sx={{padding: 0, width: 140}}
                          name="landing"
                          value={vals.landing}
                          error={hasError("landing")}
                          size="small"
                          inputProps={{ style: {textAlign: 'right'} }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Traffic Curve:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <Select
                        name="traffic_curve"
                        value={vals.traffic_curve}
                        size="small"
                        onChange={handleChange}
                      >
                      {Object.entries(misc['traffic_curve']).map(([val, display]) => (
                        <MenuItem value={val} key={val}>{display}</MenuItem>
                      ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Postback:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <Switch
                        name="postback"
                        checked={vals.postback.size > 0}
                        onChange={handleChange}
                      />
                    {vals.postback.size > 0 && (
                      <FormGroup sx={{ display: 'flex', flexDirection: 'row', ml: 1 }}>
                      {Object.entries(postbackTypes).map(([val, display]) => (
                        <FormControlLabel control={
                          <Checkbox name={"pb_type_"+val} checked={vals.postback.has(val)} onChange={handleChange} />
                        } label={display} key={val} />
                      ))}
                      </FormGroup>
                    )}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">From Adx:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <Switch
                        name="is_adx"
                        checked={vals.is_adx}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                {vals.is_adx ? (
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Adx TagID:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <OutlinedInput
                        sx={{padding: 0, width: 400}}
                        name="adx_tagid"
                        value={vals.adx_tagid}
                        error={hasError("adx_tagid")}
                        size="small"
                        inputProps={{ style: {padding: '8.5px 14px'} }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                ) : (<>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Landing Url:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <OutlinedInput
                        sx={{padding: 0, width: 400}}
                        name="dest_url"
                        value={vals.dest_url}
                        error={hasError("dest_url")}
                        size="small"
                        inputProps={{ style: {padding: '8.5px 14px'} }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Imp Trackers:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      {vals.trackers.imp.map((tkr, i) => (
                      <OutlinedInput
                        key={i}
                        sx={{padding: 0, width: 400, mt: i > 0 ? '5px' : 0}}
                        value={tkr}
                        size="small"
                        inputProps={{ style: {padding: '8.5px 14px'} }}
                        onChange={ev => changeTrackers('imp', i, ev.target.value)}
                        onBlur={handleBlur}
                        multiline
                      />
                      ))}
                      <IconButton onClick={() => addTracker('imp')} size="small">
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Click Trackers:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      {vals.trackers.clk.map((tkr, i) => (
                      <OutlinedInput
                        key={i}
                        sx={{padding: 0, width: 400, mt: i > 0 ? '5px' : 0}}
                        value={tkr}
                        size="small"
                        inputProps={{ style: {padding: '8.5px 14px'} }}
                        onChange={ev => changeTrackers('clk', i, ev.target.value)}
                        onBlur={handleBlur}
                        multiline
                      />
                      ))}
                      <IconButton onClick={() => addTracker('clk')} size="small">
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  </>
                )}
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Device Percent:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <Select
                        name="device_filter"
                        value={vals.device_filter}
                        size="small"
                        onChange={handleChange}
                        displayEmpty
                      >
                      {Object.entries(misc['device_filter']).map(([val, display]) => (
                        <MenuItem value={val} key={val}>{display}</MenuItem>
                      ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Page Actions:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <Select
                        name="ant_cfg"
                        value={vals.ant_cfg}
                        size="small"
                        onChange={handleChange}
                        displayEmpty
                      >
                      {Object.entries(misc['ant_cfg']).map(([val, display]) => (
                        <MenuItem value={val} key={val}>{display}</MenuItem>
                      ))}
                      </Select>
                      {vals.ant_cfg === '_cust_' && (
                      <OutlinedInput
                        sx={{ml: '8px', p: 0, width: 200}}
                        name="ant_cfg_cust"
                        value={vals.ant_cfg_cust}
                        error={hasError("ant_cfg_cust")}
                        size="small"
                        inputProps={{ style: {textAlign: 'left'} }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">FreqCtrl ID:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <FormControl>
                        <OutlinedInput
                          sx={{padding: 0, width: 140}}
                          name="fc_id"
                          value={vals.fc_id}
                          error={hasError("fc_id")}
                          size="small"
                          inputProps={{ style: {textAlign: 'right'} }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">FreqCtrl Max:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <Select
                        name="fc_max"
                        value={vals.fc_max}
                        size="small"
                        onChange={handleChange}
                        displayEmpty
                      >
                      {[1,2,3,4,5,6,7,8].map((n) => (
                        <MenuItem value={n} key={n}>{n}</MenuItem>
                      ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Parent ID:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <FormControl>
                        <OutlinedInput
                          sx={{padding: 0, width: 140}}
                          name="parent"
                          value={vals.parent}
                          error={hasError("parent")}
                          size="small"
                          inputProps={{ style: {textAlign: 'right'} }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Regions:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <FormControl>
                        <OutlinedInput
                          sx={{padding: 0, width: 400}}
                          name="region_names"
                          value={vals.region_names}
                          error={hasError("region_names") || hasError("regions")}
                          size="small"
                          inputProps={{ style: {textAlign: 'left'} }}
                          onChange={handleChange}
                          onBlur={handleValidateRegions}
                          required
                        />
                        <FormHelperText sx={{marginLeft: 0}}>
                          {errs?.regions ? (
                            <span style={{color: "red"}}>{errs.regions}.</span>
                          ) : (
                            <span>Left empty for countrywide.</span>
                          )}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Extra Info:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <OutlinedInput
                        sx={{padding: 0, width: 400}}
                        name="extra"
                        value={vals.extra}
                        error={hasError("extra")}
                        size="small"
                        inputProps={{ style: {padding: '8.5px 14px'} }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4} md={3}>
                      <FormLabel gutterBottom variant="subtitle1">Customize:</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <OutlinedInput
                        sx={{padding: 0, width: 400}}
                        name="customize"
                        value={vals.customize}
                        error={hasError("customize")}
                        size="small"
                        inputProps={{ style: {padding: '8.5px 14px'} }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                >
                  {saving ? "Saving..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => navigate('/jobs') }
                >
                  Cancel
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>

        { formState.customizeSchedule && (
        <Grid item md={6} xs={12}>
          <ScheduleEditor
            onChange={changeSchedule}
            startDate={vals.start_date}
            endDate={vals.end_date}
            dailyImps={vals.daily_imps}
            dayparts={vals.dayparts}
          />
        </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default JobForm;
