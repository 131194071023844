import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, Paper, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Navigation } from 'components';
import navCfg from './cfg';

const NavBar = (props) => {
  const { openMobile, onMobileClose, brief, hidden, styles, ...rest } = props;

  const loc = useLocation();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc.pathname]);

  const navbarContent = (
    <Box sx={{
      padding: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'fit-content'
    }}>
      <Navigation pages={navCfg} brief={brief} />
    </Box>
  );

  const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));

  if (hidden) return (<Box/>);
  return lgUp ? (
    <Paper
      {...rest}
      sx={{height: '100%', overflowY: 'auto', ...styles}}
      elevation={1}
      square
    >
      {navbarContent}
    </Paper>
  ) : (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      variant="temporary"
    >
      <Box
        {...rest}
        sx={{height: '100%', overflowY: 'auto', ...styles}}
      >
        {navbarContent}
      </Box>
    </Drawer>
  );
};

NavBar.propTypes = {
  styles: PropTypes.object,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  brief: PropTypes.bool
};

export default NavBar;
