import React, { useState } from 'react';
import {
  Alert,
  Box,
  Card,
  Grid,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { TimeField } from '@mui/x-date-pickers';

const styles = {
  impView: {
    display: 'inline-block',
    width: '75px',
    fontSize: '.9em',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '4px',
    marginRight: '5px',
    textAlign: 'right',
  },
  dpView: {
    display: 'inline-block',
    width: '40px',
    fontSize: '12px',
    boxSizing: 'border-box',
    borderRadius: '3px',
    padding: '3px',
    color: '#696969',
  },
  timeField: {
    '& .MuiInputBase-root': {
      width: '40px',
      '& input': {
        fontSize: '12px',
        padding: '3px',
      }
    },
    '& .MuiInputBase-input': {p: '5px'},
  }
};

const DayCard = ({day, imps, dps, isDefault, onChange}) => {
  const [editing, setEditing] = useState('');

  const dt = day.format('YYYYMMDD');
  return (
    <Card
      sx={{
        padding: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography component="h4" gutterBottom variant="overline" sx={{textTransform: 'none'}}>
          {day.format('MM-DD')}
        </Typography>
        { editing === 'imp' ? (
        <OutlinedInput
          value={imps}
          margin="dense"
          size="small"
          autoFocus={true}
          sx={{fontSize: '.9em', width: '75px'}}
          inputProps={{ style: {padding: '4px', textAlign: 'right'} }}
          onChange={ev => onChange('daily_imps', dt, ev.target.value)}
          onFocus={ev => ev.target.select()}
          onBlur={() => setEditing('')}
        />
        ) : (
        <Typography
          sx={{
            ...styles.impView,
            backgroundColor: (isDefault&1) ? '#eee' : '#dcf9f2',
          }}
          onClick={() => setEditing('imp')}
        >{imps}</Typography>
        )}
        { dps && (
        <Box sx={{
          m: '3px -3px 0',
          borderRadius: '3px',
          backgroundColor: (isDefault&2) ? '#fff' : '#f9f1d2',
        }}>
          { editing === 'dp0' ? (
            <TimeField
              sx={styles.timeField}
              format="HH:mm"
              value={dps[0]}
              onChange={val => onChange('dayparts', dt, [val, dps[1]])}
              inputProps={{ onBlur: () => setEditing('') }}
              autoFocus
            />
          ) : (
            <span
              style={styles.dpView}
              onClick={() => setEditing('dp0')}
            >{dps[0].format('HH:mm')}</span>
          )}
          <span style={{margin: "0 2px"}}>~</span>
          { editing === 'dp1' ? (
            <TimeField
              sx={styles.timeField}
              format="HH:mm"
              value={dps[1]}
              onChange={val => onChange('dayparts', dt, [dps[0], val])}
              inputProps={{ onBlur: () => setEditing('') }}
              autoFocus
            />
          ) : (
            <span
              style={styles.dpView}
              onClick={() => setEditing('dp1')}
            >{dps[1].format('HH:mm')}</span>
          )}
        </Box>)}
      </Box>
    </Card>
  );
};

const ScheduleEditor = ({onChange, startDate, endDate, dailyImps, dayparts}) => {
  if (!startDate || !endDate) {
    return (
      <Alert severity="error">Please select start date and end date!</Alert>
    );
  }

  const showDayparts = dayparts.default.length > 0 || Object.keys(dayparts).length > 1;

  const data = [];
  const defaultImps = Number(dailyImps.default);
  for (let day = startDate; !day.isAfter(endDate, 'day'); day = day.add(1, 'day')) {
    const dt = day.format('YYYYMMDD');
    let imps, dps = null, isDefault = 0;
    if (isNaN(dailyImps[dt])) {
      imps = defaultImps;
      isDefault |= 1;
    } else {
      imps = dailyImps[dt];
    }
    if (showDayparts) {
      if (dayparts[dt]) {
        dps = dayparts[dt];
      } else {
        dps = dayparts.default;
        isDefault |= 2;
      }
    }
    data.push({day, imps, dps, isDefault});
  }

  return (
    <Box>
      <Grid sx={{mt: -2}} container spacing={2}>
      {data.map(row => (
        <Grid item lg={2} sm={4} xs={6} sx={{pl:'10px !important'}} key={row.day.format('MMDD')}>
          <DayCard {...row} onChange={onChange} />
        </Grid>
      ))}
      </Grid>
    </Box>
  );
};

export default ScheduleEditor;
