import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { Popup } from 'components';

const supportedTrackers = [
  {
    name: 'Tink',
    valid: (s) => s?.clicks > 0,
    count: (s) => s.landing,
    stay:  (s) => Math.ceil(s.a_stay) + 's',
    percent: (s) => (100*s.landing/s.clicks).toFixed(1) + '%',
    bounce: (_) => '--',
  }, {
    name: 'Miaozhen',
    valid: (s) => s?.c_mz > 0,
    count: (s) => s.c_mz,
    stay:  (s) => Math.ceil(s.a_mz) + 's',
    percent: (s) => (100*s.c_mz/s.clicks).toFixed(1) + '%',
    bounce: (s) => (100*s.sb_mz/s.c_mz).toFixed(1) + '%',
  }, {
    name: 'GA',
    valid: (s) => s?.c_ga > 0,
    count: (s) => s.c_ga,
    stay:  (s) => Math.ceil(s.a_ga) + 's',
    percent: (s) => (100*s.c_ga/s.clicks).toFixed(1) + '%',
    bounce: () => '--',
  }, {
    name: 'Baidu',
    valid: (s) => s?.c_bd > 0,
    count: (s) => s.c_bd,
    stay:  (s) => Math.ceil(s.a_bd) + 's',
    percent: (s) => (100*s.c_bd/s.clicks).toFixed(1) + '%',
    bounce: () => '--',
  }, {
    name: 'Adobe',
    valid: (s) => s?.c_ab > 0,
    count: (s) => s.c_ab,
    stay:  (s) => Math.ceil(s.a_ab) + 's',
    percent: (s) => (100*s.c_ab/s.clicks).toFixed(1) + '%',
    bounce: () => '--',
  }
];

const TrackerList = ({data, onClose}) => {
  if (!data)
    return null;

  const {job, stats} = data;

  return (
    <Popup
      open={Boolean(job)}
      onClose={onClose}
      title={`${job.id} - ${job.name}`}
      styles={{width: '550px'}}
    >
      <Table size="small" sx={{
        '& th,td': {
          textAlign: 'center',
        }
      }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Arrival</TableCell>
            <TableCell>Arrival Rate</TableCell>
            <TableCell>Stay</TableCell>
            <TableCell>Bounce Rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {supportedTrackers.map(tkr => (
          <TableRow key={tkr.name}>
            <TableCell>{tkr.name}</TableCell>
            <TableCell>{tkr.valid(stats) ? tkr.count(stats) : '--'}</TableCell>
            <TableCell>{tkr.valid(stats) ? tkr.percent(stats) : '--'}</TableCell>
            <TableCell>{tkr.valid(stats) ? tkr.stay(stats) : '--'}</TableCell>
            <TableCell>{tkr.valid(stats) ? tkr.bounce(stats) : '--'}</TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </Popup>
  );
};

export default TrackerList;
