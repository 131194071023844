import palette from '../palette';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  root: {},
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary
    }
  }
};
