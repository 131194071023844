import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import {
  ArrowCircleUpOutlined as OrderIcon,
  ErrorOutline as ErrorIcon,
  PaidOutlined as DealIcon,
} from '@mui/icons-material';

import { gradients } from 'utils';

const avatars = {
  order: (
    <Avatar sx={{backgroundImage: gradients.blue}}>
      <OrderIcon />
    </Avatar>
  ),
  deal: (
    <Avatar sx={{backgroundImage: gradients.green}}>
      <DealIcon />
    </Avatar>
  ),
  error: (
    <Avatar sx={{backgroundImage: gradients.red}}>
      <ErrorIcon />
    </Avatar>
  )
};

const NotificationList = ({notifications}) => {
  return (
    <List disablePadding>
      {notifications.map((n, i) => (
        <ListItem
          sx={{
            backgroundColor: n.read ? '#F4F6F8' : '#FFF',
            '&:hover': {
              backgroundColor: '#FFF'
            }
          }}
          component={Link}
          divider={i < notifications.length - 1}
          key={n.id}
          to="#"
        >
          <ListItemAvatar>{avatars[n.type]}</ListItemAvatar>
          <ListItemText
            primary={`Pool ${n.pool_id} order ${n.order_id} ${n.message}`}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={dayjs(n.created_at).format('YYYY-MM-DD HH:mm:ss')}
          />
        </ListItem>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired
};

export default NotificationList;
