import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Typography,
} from '@mui/material';

import { callApi } from 'utils';
import { Popup } from 'components';

const idname = {
  'o': 'OAID',
  'i': 'IMEI',
  'a': 'IDFA',
};

const DeviceCheck = ({job, onClose}) => {
  const [device, setDevice] = useState(null);
  const [nth, setNth] = useState(0);

  useEffect(() => {
    job && callApi(`/jobs/${job.fc_id}/device`).then(setDevice).catch(console.error);
  }, [job, nth]);

  const getAnother = useCallback(() => setNth(n => n + 1), []);

  if (!job)
    return null;

  return (
    <Popup
      open={Boolean(job)}
      onClose={onClose}
      title={`${job.id} - ${job.name} (${job.prepared})`}
      styles={{width: '600px'}}
    >
      {device && (
      <Typography>
        <p>Device: {device.make + ' ' + device.model}</p>
        <p>OS: {device.os + ' ' + device.osv}</p>
        <p>{idname[device.did[0]]}: {device.did.substr(2)}</p>
        <p>User-Agent: {device.ua}</p>
      </Typography>
      )}
      <Button onClick={getAnother}>Check Another</Button>
    </Popup>
  );
};

export default DeviceCheck;
