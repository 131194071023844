import { useCallback, useState, useEffect } from 'react';
import joi from 'joi';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  OutlinedInput,
} from '@mui/material';

import { callApi, validate } from 'utils';
import { FormLabel } from 'components';
import useSession from 'store/session';

const schema = joi.object({
  old_password: joi.string().required(),
  password: joi.string().min(8).required(),
  repeat_password: joi.ref('password'),
});

const ChangePassword = () => {
  const logout = useSession(state => state.logout);
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    values: {
      old_password: '',
      password: '',
      repeat_password: '',
    },
    touched: {},
    errors: null,
  });

  const vals = formState.values;

  // validate form values
  useEffect(() => {
    const errors = validate(vals, schema);
    //console.log(vals, errors);
    setFormState(prev => ({
      ...prev,
      errors,
    }));
  }, [vals]);

  const handleChange = useCallback((ev) => {
    const f = ev.target;
    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [f.name]: f.value,
      },
      touched: {
        ...prev.touched,
        [f.name]: true
      }
    }));
  }, []);

  const disableSubmit = Boolean(formState.errors);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (disableSubmit) {
      return false;
    }
    callApi('/users/password', 'PUT', vals)
      .then(() => logout())
      .catch(console.error);
  };

  const hasError = (field) => Boolean(formState.touched[field] && formState.errors?.[field]);

  return (
    <form>
      <Card>
        <CardHeader
          subheader="Change password"
          title="Password"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={1} wrap="wrap">
            <Grid item container xs={12}>
              <Grid item xs={4} md={3}>
                <FormLabel gutterBottom variant="subtitle1">Current Password:</FormLabel>
              </Grid>
              <Grid item xs>
                <OutlinedInput
                  sx={{padding: 0, width: 250}}
                  name="old_password"
                  type="password"
                  value={vals.old_password}
                  error={hasError("old_password")}
                  size="small"
                  inputProps={{ style: {textAlign: 'left'} }}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={4} md={3}>
                <FormLabel gutterBottom variant="subtitle1">New Password:</FormLabel>
              </Grid>
              <Grid item xs>
                <OutlinedInput
                  sx={{padding: 0, width: 250}}
                  name="password"
                  type="password"
                  value={vals.password}
                  error={hasError("password")}
                  size="small"
                  inputProps={{ style: {textAlign: 'left'} }}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={4} md={3}>
                <FormLabel gutterBottom variant="subtitle1">New Password (Confirm):</FormLabel>
              </Grid>
              <Grid item xs>
                <OutlinedInput
                  sx={{padding: 0, width: 250}}
                  name="repeat_password"
                  type="password"
                  value={vals.repeat_password}
                  error={hasError("repeat_password")}
                  size="small"
                  inputProps={{ style: {textAlign: 'left'} }}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            Change
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => navigate('/jobs') }
          >
            Cancel
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default ChangePassword;
