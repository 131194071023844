// validate data by Joi
export default function validate(data, schema) {
  // error is a ValidationError type, see https://joi.dev/api/#validationerror
  const { error } = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
  });
  //console.log(error?.details);
  return error?.details.reduce((acc, {path, message}) => {
    acc[path.join('.')] = message;
    return acc;
  }, {});
}
