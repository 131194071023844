import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/zh-cn';

// must import before routes since routes require callApi which then require toast.
// not sure why this matters?
import { ToastContainer } from './components';

import './App.css';
import theme from './theme';
import routes from './routes';
import { useSession } from './store';

// support timezone in dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const tz = useSession(state => state.tz);
  tz && dayjs.tz.setDefault(tz);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zh-cn">
        <ToastContainer />
        <BrowserRouter>
          { routes }
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
