/* eslint-disable react/no-multi-comp */
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List, Typography } from '@mui/material';

import { NavigationListItem } from './components';

const NavigationList = props => {
  const { pages, ...rest } = props;

  return (
    <List>
      { pages.map((page, idx) => createChildItem({ idx, page, ...rest })) }
    </List>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
};

const createChildItem = props => {
  const { idx, loc, page, brief, depth } = props;

  if (page.children) {
    const open = matchPath(loc.pathname, {
      path: page.href,
      exact: false
    });

    return (
      <NavigationListItem
        key={idx}
        depth={depth}
        icon={page.icon}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
      >
        <NavigationList
          depth={depth + 1}
          pages={page.children}
          loc={loc}
        />
      </NavigationListItem>
    );
  } else {
    return (
      <NavigationListItem
        key={idx}
        depth={depth}
        brief={brief}
        href={page.href}
        icon={page.icon}
        label={page.label}
        title={page.title}
      />
    );
  }
};

const Navigation = props => {
  const { title, pages, brief, component: Component, ...rest } = props;

  const loc = useLocation();

  return (
    <Component
      {...rest}
      style={{marginBottom: 16}}
    >
      {title && <Typography variant="overline">{title}</Typography>}
      <NavigationList
        depth={0}
        pages={pages}
        brief={brief}
        loc={loc}
      />
    </Component>
  );
};

Navigation.propTypes = {
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  brief: PropTypes.bool,  // can only using without nested items
  title: PropTypes.string
};

Navigation.defaultProps = {
  component: 'nav'
};

export default Navigation;
