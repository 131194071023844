/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItem, Button, Collapse, Tooltip, colors } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <NavLink {...props} />
  </div>
));

const buttonStyle = {
  color: colors.blueGrey[800],
  padding: '10px 8px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%'
};
const expandIconStyle = {
  marginLeft: 'auto',
  height: 16,
  width: 16
};

const NavigationListItem = props => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    styles,
    open: openProp,
    brief,
    label: Label,
    ...rest
  } = props;

  // todo: fixme: this will show warning in Tooltip
  const StyledIcon = styled(Icon)(({ theme }) => ({
    color: 'palette.icon',
    display: 'flex',
    alignItems: 'center',
    marginRight: brief ? 0 : 6
  }));

  const [open, setOpen] = useState(openProp);

  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  if (children) {
    return (
      <ListItem
        {...rest}
        sx={{
          display: 'block',
          paddingY: 0,
          ...styles
        }}
        disableGutters
      >
        <Button
          sx={buttonStyle}
          onClick={() => setOpen(open => !open)}
          style={{paddingLeft}}
        >
          <StyledIcon />
          {title}
          {open ? (
            <ExpandLessIcon
              sx={expandIconStyle}
              color="inherit"
            />
          ) : (
            <ExpandMoreIcon
              sx={expandIconStyle}
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        sx={{
          display: 'flex',
          paddingY: 0,
          ...styles
        }}
        disableGutters
      >
        <Button
          className={`depth-${depth}`}
          sx={{
            ...buttonStyle,
            fontWeight: 'typography.fontWeightRegular',
            '&.depth-0': {
              fontWeight: 'typography.fontWeightMedium'
            },
            ':active': {
              color: 'palette.primary.main',
              fontWeight: 'typography.fontWeightMedium',
              '& $icon': {
                color: 'palette.primary.main'
              }
            }
          }}
          component={CustomRouterLink}
          style={{paddingLeft, minWidth: 'auto'}}
          to={href}
        >
          {brief ? (
            <Tooltip title={title} placement="right">
              <Icon />
            </Tooltip>
          ) : (
            <>
              <StyledIcon />
              <span>{title}</span>
            </>
          )}
          {Label && (
            <span style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
              <Label />
            </span>
          )}
        </Button>
      </ListItem>
    );
  }
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any.isRequired,
  label: PropTypes.any,
  open: PropTypes.bool,
  brief: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavigationListItem.defaultProps = {
  depth: 0,
  open: false
};

export default NavigationListItem;
