import React from 'react';
import { Box, Typography } from '@mui/material';

const EmptyList = props => {
  return (
    <Box
      {...props}
      sx={{
        textAlign: 'center',
        padding: 3
      }}
    >
      <Box sx={{
        height: 240,
        backgroundImage: 'url("/images/undraw_empty.svg")',
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }} />
      <Typography variant="h4">There is nothing here...</Typography>
    </Box>
  );
};

export default EmptyList;
