import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors
} from '@mui/material';

import { NotificationList, EmptyList } from './components';

const NotificationsPopover = props => {
  const { notifications, anchorEl, ...rest } = props;

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Box sx={{width: 350, maxWidth: '100%'}}>
        <CardHeader title="Notifications" />
        <Divider />
        {notifications.length > 0 ? (
          <NotificationList notifications={notifications} />
        ) : (
          <EmptyList />
        )}
        <Divider />
        <CardActions sx={{
          backgroundColor: colors.grey[50],
          justifyContent: 'center'
        }}>
          <Button
            component={Link}
            size="small"
            to="#"
          >
            See all
          </Button>
        </CardActions>
      </Box>
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default NotificationsPopover;
