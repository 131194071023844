import React, { lazy } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

import { withAuth } from 'utils';
import { AuthLayout, ErrorLayout, MainLayout } from 'layouts';
//import Dashboard from './views/Dashboard';
import JobList from './views/JobList';
import JobForm from './views/JobForm';
import BatchAdd from './views/BatchAdd';
import Report from './views/Report';
import Settings from './views/Settings';

const Login = lazy(() => import('views/Login'));
//const Error401 = lazy(() => import('views/Error401'));
const Error404 = lazy(() => import('views/Error404'));
//const Error500 = lazy(() => import('views/Error500'));

const AuthMainLayout = withAuth()(MainLayout);

const routes = (
  <Routes>
    <Route path="/" element={<Outlet />}>
      <Route index element={<Navigate to="/jobs" />} />

      <Route path="auth" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Error404 />} />
      </Route>

      <Route path="errors" element={<ErrorLayout />}>
        <Route path="401" element={<Error404 />} />
        <Route path="500" element={<Error404 />} />
        <Route path="*" element={<Error404 />} />
      </Route>

      <Route path="*" element={<AuthMainLayout />}>
        <Route path="reports" element={<Report />} />
        <Route path="settings" element={<Settings />} />

        <Route path="jobs" element={<Outlet />}>
          <Route index element={<JobList />} />
          <Route path="new" element={<JobForm />} />
          <Route path="batchadd" element={<BatchAdd />} />
          <Route path=":jobId/edit" element={<JobForm />} />
        </Route>

        <Route path="*" element={<Navigate to="/errors/401" />} />
      </Route>
    </Route>
  </Routes>
);

export default routes;
