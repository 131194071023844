import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  useMediaQuery
} from '@mui/material';
import {
  NotificationsOutlined as NotificationsIcon,
  Input as InputIcon,
  Menu as MenuIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

import useSession from 'store/session';
import { NotificationsPopover } from 'components';
//import { sseFetchData } from 'utils/callApi';
import { callApi } from 'utils';

const TopBar = ({onOpenNavBarMobile, styles}) => {
  const logout = useSession(state => state.logout);
  const notificationsRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  useEffect(() => {
    //sseFetchData('/notifications', (msgs) => {
    //  setNotifications(prev => [...prev, ...msgs].slice(-30));
    //});
    let from = localStorage.getItem('notification') || '';
    const itvl = setInterval(() => {
      callApi(`/notifications?from=${encodeURIComponent(from)}`)
        .then(msgs => {
          if (msgs?.length) {
            setNotifications(prev => msgs.length >= 8 ? msgs : [...msgs, ...prev].slice(0, 8));
            from = msgs[0].created_at;
            localStorage.setItem("notification", from);
          }
        })
        .catch(console.log);
    }, 300_000);

    return () => clearInterval(itvl);
  }, []);

  const handleLogout = () => {
    logout();
    // AuthGuard should handle this
    //history.push('/auth/login');
  };

  const handleNotificationsOpen = () => setNotificationsOpen(true);
  const handleNotificationsClose = () => {
    setNotificationsOpen(false);
    setNotifications(prev => prev.map(n => ({...n, read: true})));
  }

  const unreadNotifications = notifications.filter(n => !n.read).length;

  const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        ...styles
      }}
      color="primary"
    >
      <Toolbar variant="dense">
        <Link to="/" style={{height:"40px"}}>
          <img alt="Logo" src="/logo.png" />
        </Link>
        <Box sx={{flexGrow: 1}} />
        { lgUp ? (
        <>
          <IconButton
            sx={{marginLeft: 1}}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge badgeContent={unreadNotifications} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            component={Link} to="/settings"
          >
            <SettingsIcon />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon />
          </IconButton>
        </>
        ) : (
        <>
          <IconButton
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </>
        )}
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={notificationsOpen}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  styles: PropTypes.object,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
