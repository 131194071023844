import React, { Suspense, useState } from 'react';
import { Outlet } from "react-router-dom";
import { LinearProgress, Stack, Box } from '@mui/material';

import { NavBar, TopBar } from './components';

const Main = (_props) => {
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  return (
    <Stack sx={{
      height: '100vh',
      width: '100%',
      overflow: 'hidden'
    }}>
      <TopBar
        styles={{zIndex: 2, position: 'relative'}}
        onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
      />
      <Stack direction="row" sx={{ flex: '1 1 auto', overflow: 'hidden'}}>
        <NavBar
          styles={{
            zIndex: 3,
            width: 60,
            minWidth: 60,
            flex: '0 0 auto'
          }}
          onMobileClose={() => setOpenNavBarMobile(false)}
          openMobile={openNavBarMobile}
          brief
          hidden
        />
        <Box sx={{overflowY: 'auto', flex: '1 1 auto'}}>
          <Suspense fallback={<LinearProgress />}>
            {/* An <Outlet> renders whatever child route is currently active,
                so you can think about this <Outlet> as a placeholder for
                the child routes we defined above. */}
            <Outlet />
          </Suspense>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Main;
