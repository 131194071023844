import {
  Stack,
} from '@mui/material';

import { Page } from 'components';
import { ChangePassword } from './components';

const Settings = () => {
  return (
    <Page styles={{padding: 3}} title="Settings">
        <Stack spacing={3}>
          <ChangePassword />
        </Stack>
    </Page>
  );
};

export default Settings;
