import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  Link as LinkIcon,
} from '@mui/icons-material';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const LinkDetail = ({destUrl, trackers}) => (
  <Box sx={{p: 1}}>
    <Typography sx={{"&:not(:last-child)": {mb: 1.5}}}>
      {destUrl || 'No Landing URL'}
    </Typography>
    {trackers?.imp && (
    <>
      <Typography component="h4" variant="overline">Impression Trackers:</Typography>
      <List dense={true}>
      {trackers.imp.map((tkr, i) =>
        <ListItem key={i} sx={{p: 0}}>
          <ListItemIcon sx={{minWidth: 0}}><LinkIcon /></ListItemIcon>
          <ListItemText sx={{ml: 1}} primary={tkr} />
        </ListItem>
      )}
      </List>
    </>
    )}
    {trackers?.clk && (
    <>
      <Typography component="h4" variant="overline">Click Trackers:</Typography>
      <List dense={true}>
      {trackers.clk.map((tkr, i) =>
        <ListItem key={i} sx={{p: 0}}>
          <ListItemIcon sx={{minWidth: 0}}><LinkIcon /></ListItemIcon>
          <ListItemText sx={{ml: 1}} primary={tkr} />
        </ListItem>
      )}
      </List>
    </>
    )}
  </Box>
);

const LinkView = ({destUrl, trackers}) => (
  <HtmlTooltip title={<LinkDetail destUrl={destUrl} trackers={trackers} />}>
    <Typography noWrap={true} sx={{ maxWidth: 160, display: 'inline-block' }}>
      {destUrl || 'No Landing URL'}
    </Typography>
  </HtmlTooltip>
);

export default LinkView;
