import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { callApi, formatFieldName } from 'utils';
import { Popup } from 'components';

const AggReport = ({agg, onClose}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    agg && callApi(`/reports?dim=${agg}`).then(setData).catch(console.error);
  }, [agg]);

  if (!agg)
    return null;

  return (
    <Popup
      open={Boolean(agg)}
      onClose={onClose}
      title={data?.title || 'AggReport'}
      styles={{width: '400px'}}
    >
      <Table size="small" sx={{
        '& th,td': {
          textAlign: 'center',
        }
      }}>
        <TableHead>
          <TableRow>
            {data?.columns.map(col => (<TableCell key={col}>{formatFieldName(col)}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.rows.length && (<TableRow><TableCell colSpan={data?.columns.length || 1}>No data</TableCell></TableRow>)}
          {data?.rows.map(row => (
            <TableRow hover key={row.key || row.date}>
              {data?.columns.map(col => (<TableCell key={col}>{row[col]}</TableCell>))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Popup>
  );
};

export default AggReport;
