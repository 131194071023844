import { toast } from 'components';

const BASE_URL = '/api';

export default async function callApi(endpoint, method='GET', data=null, authenticated=true) {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  };
  if (data) {
    options.body = JSON.stringify(data);
  }

  try {
    if (authenticated) {
      const token = localStorage.getItem('token');
      if (token) {
        options.headers.Authorization = `Bearer ${token}`;
      } else {
        throw new Error("No token available!")
      }
    }

    const resp = await fetch(BASE_URL + endpoint, options);
    const result = await resp.json();
    if (!resp.ok) {
      throw new Error(result.message);
    }
    return result;
  } catch(ex) {
    toast(`Error call api: ${ex.message || ex}`, 'error')
    throw ex;
  }
}
