export function modifyArrayIndex(arr, idx, changes) {
  if (idx >= arr.length)
    return arr;

  return [
    ...arr.slice(0, idx),
    {
      ...arr[idx],
      ...changes,
    },
    ...arr.slice(idx+1)
  ];
}

export function modifyArrayItem(arr, item_, changes) {
  const i = arr.findIndex(item => item === item_);
  if (i < 0)
    return arr;

  return modifyArrayIndex(arr, i, changes);
}

export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function formatFieldName(s) {
  return s.split('_').map(w => capitalize(w)).join(' ');
}
