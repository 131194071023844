import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

// https://create-react-app.dev/docs/adding-custom-environment-variables/
const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const Page = ({title, styles, children, ...rest}) => {
  const loc = useLocation();

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    if (window.gtag && GA_MEASUREMENT_ID) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: loc.pathname,
        page_name: title
      });
    }
  }, [title, loc]);

  return (
    <Box sx={styles} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
  title: PropTypes.string
};

export default Page;
