import React, { Suspense } from 'react';
import { Outlet } from "react-router-dom";
import {
  AppBar,
  Box,
  LinearProgress,
  Toolbar 
} from '@mui/material';

const Auth = () => {
  return (
    <>
      <AppBar
        xs={{ boxShadow: 'none' }}
        color="primary"
      >
        <Toolbar>
          <img alt="Logo" src="/logo.png" />
        </Toolbar>
      </AppBar>
      <Box sx={{
        height: '100%',
        paddingTop: 20,
        sm: { paddingTop: 20 },
      }}>
        <Suspense fallback={<LinearProgress />}>
          {/* An <Outlet> renders whatever child route is currently active,
              so you can think about this <Outlet> as a placeholder for
              the child routes we defined above. */}
          <Outlet />
        </Suspense>
      </Box>
    </>
  );
};

export default Auth;
