import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Card,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import { callApi } from 'utils';
import { Popup } from 'components';

const DayCard = ({day, totalImps, totalClks, dayImps}) => {
  return (
    <Card
      sx={{
        position: 'relative',
        p: 1,
        mb: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: "transform 0.15s ease-in-out",
        '&:hover': {
          zIndex: 100,
        },
        '& .details': {
          height: '24px',
          minHeight: '24px',
        },
        '&:hover .details': {
          transform: "scale3d(1.02, 1.02, 1)",
          height: 'auto',
        },
      }}
    >
      <Box>
        <Typography variant="caption" component="div" gutterBottom sx={{mt: '-3px'}}>{day}</Typography>
        <Tooltip title={'Clicks: '+totalClks}>
          <Typography variant="h5">{totalImps}</Typography>
        </Tooltip>
        <Box className="details">
          {dayImps.map(d => (
            <Typography variant="h6" key={d.id}>
              <Tooltip title={d.name}>
                <Typography sx={{px: '3px'}} component="span">{d.id}:</Typography>
              </Tooltip>
              <Tooltip title={d.ctr+'%'}>
                <Typography component="span">{d.imps}</Typography>
              </Tooltip>
            </Typography>
          ))}
        </Box>
      </Box>
    </Card>
  );
};

const Overview = ({open, onClose}) => {
  const [jobs, setJobs] = useState([]);

  // reload when reopen
  useEffect(() => {
    open && callApi(`/jobs/overview`).then(setJobs).catch(console.error);
  }, [open]);

  if (!open)
    return null;

  let maxEndDate = dayjs();
  jobs.forEach(job => {
    job.start_date = dayjs(job.start_date);
    job.end_date = dayjs(job.end_date);
    job.end_date.isAfter(maxEndDate, 'day') && (maxEndDate = job.end_date);
  });

  // build every day overview from today
  const data = [];
  for (let d = dayjs(); !d.isAfter(maxEndDate, 'day'); d = d.add(1, 'day')) {
    const dt = d.format('YYYYMMDD');
    const dayImps = [];
    let totalImps = 0, totalClks = 0;
    jobs.forEach(job => {
      if (d.isBefore(job.start_date, 'day') || d.isAfter(job.end_date, 'day'))
        return;
      const imps = isNaN(job.daily_imps[dt]) ? job.daily_imps.default : job.daily_imps[dt];
      if (imps > 0) {
        totalImps += imps;
        totalClks += imps*job.ctr/100 |0;
        dayImps.push({
          id: job.id,
          name: job.name,
          ctr: job.ctr,
          imps, 
        });
      }
    });
    data.push({
      day: d.format('MM-DD'),
      totalImps,
      totalClks,
      dayImps,
    });
  }

  return (
    <Popup
      open={open}
      onClose={onClose}
      title="Schedule Overview"
    >
      <Grid sx={{mt: 0}} container spacing={2}>
      {data.map(day => (
        <Grid sx={{position: 'relative', height: '93px'}} item lg={2} sm={4} xs={6} key={day.day}>
          <DayCard {...day} />
        </Grid>
      ))}
      </Grid>
    </Popup>
  );
};

export default Overview;
